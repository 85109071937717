export const UnitMapper: Record<string, { singular: string; plural: string }> =
  {
    segundo: {
      singular: "segundo",
      plural: "segundos",
    },
    hora: {
      singular: "hora",
      plural: "horas",
    },
    día: {
      singular: "día",
      plural: "días",
    },
    semana: {
      singular: "semana",
      plural: "semanas",
    },
    mes: {
      singular: "mes",
      plural: "meses",
    },
    año: {
      singular: "año",
      plural: "años",
    },
    ampolla: {
      singular: "ampolla",
      plural: "ampollas",
    },
    aplicación: {
      singular: "aplicación",
      plural: "aplicaciones",
    },
    cápsula: {
      singular: "cápsula",
      plural: "cápsulas",
    },
    comprimido: {
      singular: "comprimido",
      plural: "comprimidos",
    },
    gota: {
      singular: "gota",
      plural: "gotas",
    },
    "gr (gramo)": {
      singular: "gr (gramo)",
      plural: "grs (gramos)",
    },
    "ml (mililitro)": {
      singular: "ml (mililitro)",
      plural: "mls (mililitros)",
    },
    parche: {
      singular: "parche",
      plural: "parches",
    },
    puff: {
      singular: "puff",
      plural: "puffs",
    },
    sobre: {
      singular: "sobre",
      plural: "sobres",
    },
    supositorio: {
      singular: "supositorio",
      plural: "supositorios",
    },
    tarro: {
      singular: "tarro",
      plural: "tarros",
    },
    "mg (milígramo)": {
      singular: "mg (milígramo)",
      plural: "mgs (milígramos)",
    },
    unidad: {
      singular: "unidad",
      plural: "unidades",
    },
    laboratorio: {
      singular: "laboratorio",
      plural: "laboratorios",
    },
    cajaDispensada: {
      singular: "caja dispensada",
      plural: "cajas dispensadas",
    },
    caja: {
      singular: "caja",
      plural: "cajas",
    },
  } as const;

export function getUnitForQuantity(unit: string, quantity: number) {
  if (!UnitMapper[unit]) return unit;
  return quantity > 1 ? UnitMapper[unit].plural : UnitMapper[unit].singular;
}
